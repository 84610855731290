import {
    OurServiceIconsS1,
    OurServiceIconsS2,
    OurServiceIconsS3,
    OurServiceIconsS4,
} from '../../utils/allImgs'

const OurServiceContent = [
    {
        img:OurServiceIconsS1,
        title:"IT &amp; Cloud Managment",
        text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, maxi ut accumsan ut, posuere sit Lorem ipsum quam, maximus ut accumsan."
    },
    {
        img:OurServiceIconsS2,
        title:"Diployment and Migration",
        text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, maxi ut accumsan ut, posuere sit Lorem ipsum quam, maximus ut accumsan."
    },
    {
        img:OurServiceIconsS3,
        title:"Fastest VPN Services",
        text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, maxi ut accumsan ut, posuere sit Lorem ipsum quam, maximus ut accumsan."
    },
    {
        img:OurServiceIconsS4,
        title:"Managed Web Application",
        text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, maxi ut accumsan ut, posuere sit Lorem ipsum quam, maximus ut accumsan."
    }
]

export default OurServiceContent